import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UserModel} from '../models/user.models';
import {UsersService} from './user.service';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class AuthService {
  public isAuthenticated;
  date = new Date().toDateString();
  user: UserModel;

  constructor(private userService: UsersService, private router: Router, private cookie: CookieService) {
  }

  login() {
    window.localStorage.setItem('date', this.date);
    window.localStorage.setItem('param', 'fkjvajkfnadfvsdvhfrryh');
    this.isAuthenticated = true;
  }

  logout() {
    this.userService.getUserOut().subscribe((res: Response) => {
      },
      ErrorEvent => {
      },
      () => {
        this.router.navigate(['/login']);
        this.isAuthenticated = false;
        window.localStorage.removeItem('date');
        window.localStorage.removeItem('param');
        window.localStorage.removeItem('locale');
        this.cookie.deleteAll();
      });
  }

  logoutUnAuth() {
    this.userService.getUserOut().subscribe((res: Response) => {
      },
      ErrorEvent => {
      },
      () => {
        this.router.navigate(['/login'], {
          queryParams: {
            goOut: true
          }
        });
        this.isAuthenticated = false;

        window.localStorage.removeItem('date');
        window.localStorage.removeItem('param');
        window.localStorage.removeItem('locale');
        this.cookie.deleteAll();
      });
  }

  logoutWithEnter() {
    this.userService.getUserOut().subscribe((res: Response) => {
      },
      ErrorEvent => {
      },
      () => {
        this.router.navigate(['/login'], {
          queryParams: {
            accessDenied: true
          }
        });
        this.isAuthenticated = false;

        window.localStorage.removeItem('date');
        window.localStorage.removeItem('param');
        window.localStorage.removeItem('locale');
        this.cookie.deleteAll();
      });
  }

  logoutUnError() {
    if (this.router.url !== '/login') {
      this.router.navigate(['/login'], {
        queryParams: {
          error: true
        }
      });
    }
    this.isAuthenticated = false;
    window.localStorage.removeItem('param');
    window.localStorage.removeItem('date');
    window.localStorage.removeItem('locale');
    this.cookie.deleteAll();
  }

  isLoggedIn(): boolean {
    this.isAuthenticated = window.localStorage.getItem('param') === 'fkjvajkfnadfvsdvhfrryh' && window.localStorage.getItem('date') === this.date;
    return this.isAuthenticated;
  }
}
