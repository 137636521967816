import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {AuthComponent} from './auth.component';
import {CommonModule} from '@angular/common';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {AuthRoutingModule} from './auth-rounting.module';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SharedModule} from '../shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

export const options1: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    LoginComponent,
    AuthComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    NgxMaskModule.forRoot(options1)
  ]
})

export class AuthModule {
}
