import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Meta, Title} from '@angular/platform-browser';
import {UserUserModel} from '../../shared/models/UserUserModel';
import {UserModel} from '../../shared/models/user.models';
import {UsersService} from '../../shared/services/user.service';
import {AuthService} from '../../shared/services/auth.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  user: UserModel;
  user1: UserUserModel;

  constructor(private userService: UsersService, private authService: AuthService, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta, private cookie: CookieService) {
    title.setTitle('Вход в РМК');
    meta.addTags([
      {name: 'keywords', content: 'логин, вход, авторизация, система'},
      {name: 'description', content: 'Страница для входа в систему'}
    ]);
  }

  ngOnInit() {
    this.form = new FormGroup({
      login: new FormControl(null, [Validators.required, Validators.maxLength(256)]),
      password: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(256)])
    });

    this.route.queryParams.subscribe((params: Params) => {
      // из гварда
      if (params.accessDenied) {
        this.userService.openWarningrSnackBar('Для работы с системой вам необходимо войти.', '');
      }
    });

    this.route.queryParams.subscribe((params: Params) => {
      if (params.goOut) {
        this.userService.openWarningrSnackBar('В системе может работать только 1 менеджер одновременно.', '');
      }
    });

    this.route.queryParams.subscribe((params: Params) => {
      if (params.error) {
        this.userService.openDangerSnackBar('Технические неполадки. Попробуйте позже.', '');
      }
    });
  }

  onSubmit() {
    this.user = new UserModel(this.form.value.login, this.form.value.password);
    this.user1 = new UserUserModel(this.user);
    this.userService.getUserByLogin(this.user1).subscribe((user: string) => {
    }, (error: HttpErrorResponse) => {
      if (error.status === 500) {
        this.userService.openDangerSnackBar('Технические неполадки. Попробуйте позже.', '');
      } else if (error.status === 0) {
        this.userService.openDangerSnackBar('Отсутсвует интернет соединение. Попробуйте позже.', '');
      } else if (error.status === 403) {
        this.userService.openDangerSnackBar('Неправильный логин или пароль', '');
      } else {
        this.userService.openDangerSnackBar(error.error, '');
      }
    }, () => {
      this.userService.getSes().subscribe((res: string) => {
          this.authService.login();
          this.router.navigate(['/system', 'cashbox']);
        },
        (error: HttpErrorResponse) => {
          this.userService.openDangerSnackBar(error.error, '');
        });
    });
  }
}
