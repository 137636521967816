import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {AuthModule} from './auth/auth.module';
import {UsersService} from './shared/services/user.service';
import {CookieService} from 'ngx-cookie-service';
import {LoaderService} from './shared/services/loader.service';
import {SystemInterceptor} from './shared/interceptors/system.interceptor';
import {AuthService} from './shared/services/auth.service';
import {AuthInterceptor} from './shared/interceptors/auth.interceptor';
import {CardsService} from './shared/services/cards.service';
import {LoaderInterceptor} from './shared/interceptors/loader.interceptor';
import {AuthGuard} from './shared/services/auth.guard';
import {NotFoundComponent} from './not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    HttpClientModule,
    MatSnackBarModule

  ],
  providers: [UsersService, AuthService, CardsService, LoaderService, CookieService,
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true}, AuthGuard,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SystemInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
