<div *ngIf="position === false">
  <div *ngIf="isLoading | async" class="overlay">
    <mat-progress-spinner [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>
</div>

<div *ngIf="position === true">
  <div *ngIf="isLoading | async" class="overlay1">
    <mat-progress-spinner [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>
</div>
