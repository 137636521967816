import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import { LoaderComponent } from './loader/loader.component';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  imports : [ReactiveFormsModule, FormsModule, MatProgressSpinnerModule, CommonModule],
  exports : [ReactiveFormsModule, FormsModule, LoaderComponent],
  declarations: [LoaderComponent]
})

export class SharedModule {}
