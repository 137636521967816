import {Component} from '@angular/core';
import {UsersService} from '../shared/services/user.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent {
  constructor(private usersService: UsersService, private title: Title, private meta: Meta) {
    title.setTitle('Страница не найдена');
    meta.addTags([
      {name: 'keywords', content: '404, ошибка'},
      {name: 'description', content: 'Страница не найдена'}
    ]);
  }
}
