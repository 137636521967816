<div class="auth">
  <div class="auth-container">
    <div class="card"
         style="height: unset !important; min-height: 450px;">
      <app-loader [position]="true"></app-loader>

      <div class="overlay1" *ngIf="loadingRouteConfig">
        <mat-progress-spinner color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
        <p>Интерфейс грузится, пожалуйста, подождите</p>
      </div>

      <header class="auth-header">
        <h1 class="auth-title">
          <img src="../../assets/images/teyca.svg" alt="logo" class="img-fluid">
        </h1>
      </header>

      <div class="auth-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
