import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {UserModel} from '../models/user.models';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CardModels} from '../models/card.models';
import {OperationModels} from '../models/operation.models';
import {TypeDiscountOperationModels} from '../models/TypeDiscountOperation.models';
import {BeforeOrdersModels} from '../models/beforeOrders.models';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable()
export class CardsService {
  user: UserModel;
  domain_zone = 'ru';

  constructor(private http: HttpClient, private authService: AuthService, private _snackBar: MatSnackBar) {
    if (window.location.hostname.includes('.kz')) {
      this.domain_zone = 'kz';
    }
  }

  openDangerSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'danger-snack'
    });
  }

  openWarningrSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'warning-snack'
    });
  }

  openSuccessSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'success-snack'
    });
  }

  getSettingLoyalty() {
    return this.http.get<any>(`https://rmks.teyca.${this.domain_zone}/rmksettings`, httpOptions);
  }

  postFindPass(formvalue: string) {
    return this.http.post<any>(`https://rmks.teyca.${this.domain_zone}/searchuser`, {search: formvalue}, httpOptions);
  }

  postCreatePass(formvalue: CardModels) {
    return this.http.post<any>(`https://rmks.teyca.${this.domain_zone}/createuser`, {user: formvalue}, httpOptions);
  }

  sendCard(type: string, user_id: number) {
    return this.http.post<Response>(`https://rmks.teyca.${this.domain_zone}/sendusercard`, {
      send_by: type,
      user_id: user_id
    }, httpOptions);
  }

  postStartOperation(value: OperationModels) {
    return this.http.post<TypeDiscountOperationModels>(`https://rmks.teyca.${this.domain_zone}/operation`, value, httpOptions);
  }

  postVerSpisBonus(user: any) {
    return this.http.post<any>(`https://rmks.teyca.${this.domain_zone}/verify`, {user}, httpOptions);
  }

  postBonusWriteOff(user: any, bonus_write_off: number) {
    return this.http.post<any>(`https://rmks.teyca.${this.domain_zone}/bonuswriteoff`, {
      user,
      bonus_write_off
    }, httpOptions);
  }

  postCancelWriteOff(user: any) {
    return this.http.post<any>(`https://rmks.teyca.${this.domain_zone}/bonuscancel`, {user}, httpOptions);
  }

  postSubmitOperation(user: any, own_operation_number: any) {
    return this.http.post<any>(`https://rmks.teyca.${this.domain_zone}/submitoperation`, {
      user,
      own_operation_number
    }, httpOptions);
  }

  postOrders(user_id: number, offset: number, limit: number) {
    return this.http.post<BeforeOrdersModels>(`https://rmks.teyca.${this.domain_zone}/getorders`, {
      user_id,
      offset,
      limit
    }, httpOptions);
  }

  postReturnSell(operation_number: number, summ_return: number) {
    return this.http.post<Response>(`https://rmks.teyca.${this.domain_zone}/operationreturn`, {
      operation_number,
      summ_return
    }, httpOptions);
  }
}
