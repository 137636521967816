import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserUserModel} from '../models/UserUserModel';
import {MatSnackBar} from '@angular/material/snack-bar';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable()
export class UsersService {
  domain_zone = 'ru';

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {
    if (window.location.hostname.includes('.kz')) {
      this.domain_zone = 'kz';
    }
  }

  openDangerSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'danger-snack'
    });
  }

  openWarningrSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'warning-snack'
    });
  }

  openSuccessSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'success-snack'
    });
  }

  getUserByLogin(user: UserUserModel) {
    return this.http.post<string>(`https://rmks.teyca.${this.domain_zone}/auth/login1`, user, httpOptions);
  }

  getUserOut() {
    return this.http.get<Response>(`https://rmks.teyca.${this.domain_zone}/auth/logout`, httpOptions);
  }

  getSes() {
    return this.http.post<string>(`https://rmks.teyca.${this.domain_zone}/setsession`, httpOptions);
  }

  getlocale() {
    return this.http.get<string>(`https://rmks.teyca.${this.domain_zone}/locale`, httpOptions);
  }

  checkUser() {
    return this.http.get<string>(`https://rmks.teyca.${this.domain_zone}/checkuser`, httpOptions);
  }
}
