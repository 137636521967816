import {Observable} from 'rxjs';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';

import {Router} from '@angular/router';
import {CardsService} from '../services/cards.service';
import {AuthService} from '../services/auth.service';

@Injectable()
export class SystemInterceptor implements HttpInterceptor {
  constructor(private messageCard: CardsService, private router: Router, private authsevice: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(tap(() => {
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 400) {
            this.messageCard.openDangerSnackBar(err.error.toString(), '');
          }
          if (err.status === 418) {
            this.messageCard.openWarningrSnackBar(err.error.toString(), '');
          }
          if (err.status === 500) {
            this.authsevice.logoutUnError();
          }
          if (err.status === 0) {
            this.authsevice.logoutUnError();
          }
          return;
        }
      },
      () => {
      }
    ));
// this.messageCard.openSuccessSnackBar(HttpResponse.prototype.headers.get('Success-message'), '');
  }
}
