<p class="text-xs-center">Вход в рабочее место кассира</p>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group" [ngClass]="{'has-error': form.get('login').invalid && form.get('login').touched}">
    <label for="login">Логин:</label>
    <input
      type="text"
      class="form-control underlined"
      id="login"
      placeholder="Введите ваш логин"
      formControlName="login"
    >
    <span class="form-help-text" *ngIf="form.get('login').invalid && form.get('login').touched">
      <span *ngIf="form.get('login')['errors']['required']">Логин не может быть пустым.</span>
      </span>
  </div>

  <div class="form-group" [ngClass]="{'has-error': form.get('password').invalid && form.get('password').touched}">
    <label for="password">Пароль:</label>
    <input
      type="password"
      class="form-control underlined"
      id="password"
      placeholder="Пароль"
      formControlName="password"

    >
    <span class="form-help-text" *ngIf="form.get('password').invalid && form.get('password').touched">
      <span *ngIf="form.get('password')['errors']['required']">Пароль не может быть пустым.</span>
      <span
        *ngIf="form.get('password')['errors']['minlength'] ">Пароль не может быть меньше {{form.get('password')['errors']['minlength']['requiredLength']}}
        символов,
        сейчас введено: {{form.get('password')['errors']['minlength']['actualLength']}}.
      </span></span>
  </div>
  <div class="form-group">
    <button type="submit" class="btn btn-block btn-primary" [disabled]="form.invalid">Войти</button>
  </div>
</form>
