import {Observable} from 'rxjs';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authservice: AuthService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true
    });

    return next.handle(request).pipe(tap(() => {
      },
      (err: any) => {

        if (err instanceof HttpErrorResponse) {
          if (err.status === 423) {
            this.authservice.logoutUnAuth();
          } else if (err.status === 403) {
            this.authservice.logoutWithEnter()
          } else {
            return;
          }
        }
      }
    ));
  }
}
